// confirm page
import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import api from '../../utils/api';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 15%;
  min-height: 100vh;
  background-color: #f7f8fc;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
`;

const StoreName = styled.h1`
  font-size: 1.6rem;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 1.5rem;
`;

const Title = styled.h2`
  font-size: 1.4rem;
  font-weight: 600;
  color: #444;
  text-align: center;
  margin-bottom: 1.2rem;
`;

const OrderDate = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: #777;
  text-align: center;
  margin-bottom: 1.5rem;
`;

const Description = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: #666;
  text-align: center;
  margin-bottom: 2rem;
`;

const NameInput = styled.input`
  font-size: 1rem;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  width: 100%;
  margin-bottom: 2rem;

  &:focus {
    outline: none;
    border-color: #5762d5;
  }
`;

const ConfirmBtn = styled.button`
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 12px;
  border-radius: 5px;
  border: none;
  width: 100%;
  background-color: #5762d5;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #4055b2;
  }
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #5762d5;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Confirm: React.FC = () => {
  const [deliveryStatus, setDeliveryStatus] = useState<string>('');
  const [driverName, setDriverName] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [storeName, setStoreName] = useState<string>('');
  const [createdDate, setCreatedDate] = useState<string>('');
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const location = useLocation();
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const hash = params.get('hash');

  const { orderId } = useParams();

  const unixToDate = (unix: number) => {
    const date = new Date(unix * 1000);
    return date.toLocaleDateString();
  };

  useEffect(() => {
    const storedDriverName = localStorage.getItem('driverName');
    if (storedDriverName) {
      setDriverName(storedDriverName);
    }
  }, []);

  useEffect(() => {
    api
      .checkDeliveryStatus(orderId)
      .then((response) => {
        setDeliveryStatus(response.data.orderStatus);
        setStoreName(response.data.storeName);
        setCreatedDate(unixToDate(response.data.createdTs));
        if (response.data.orderStatus === 'claimed') {
          setMessage('Delivery Completed!');
        } else if (response.data.orderStatus === 'cancelled') {
          setMessage('This order has been cancelled!');
        }
      })
      .catch((error) => {
        setDeliveryStatus('error');
        setMessage(error.response.data.message);
        return;
      });
  }, [orderId]);

  const handleClaimOrder = () => {
    localStorage.setItem('driverName', driverName);

    if (driverName === '') {
      alert('Please enter your name');
      return;
    }

    setIsConfirming(true); // Start the loading animation

    api
      .updateDeliveryStatus(orderId, { driverName }, hash)
      .then((response) => {
        if (response.data.success) {
          setDeliveryStatus('claimed');
          setMessage('Success');
        }
      })
      .catch((error) => {
        console.error('Error claiming order', error);
        setDeliveryStatus('error');
        setMessage(error.response.data.message);
        return;
      })
      .finally(() => {
        setIsConfirming(false); // Stop the loading animation regardless of success or error
      });
  };

  if (deliveryStatus === 'delivering') {
    return (
      <Wrapper>
        <StoreName>{storeName}</StoreName>
        <Title>Delivery Confirmation Form</Title>
        <OrderDate>{createdDate}</OrderDate>
        <Description>Enter your name to complete the delivery</Description>
        <NameInput
          type="text"
          value={driverName}
          onChange={(e) => setDriverName(e.target.value)}
          placeholder="Butler Boy Name"
        />
        {isConfirming ? (
          <LoadingSpinner />
        ) : (
          <ConfirmBtn onClick={handleClaimOrder}>Confirm</ConfirmBtn>
        )}
      </Wrapper>
    );
  } else if (deliveryStatus === 'claimed' || deliveryStatus === 'cancelled') {
    return (
      <Wrapper>
        <StoreName>{storeName}</StoreName>
        <Title>Delivery Confirmation Forms</Title>
        <OrderDate>{createdDate}</OrderDate>
        <Title>{message}</Title>
      </Wrapper>
    );
  } else if (deliveryStatus === 'error') {
    return <div>{message}</div>;
  } else {
    return <div>Loading...</div>;
  }
};

export default Confirm;
