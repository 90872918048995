import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Outlet } from 'react-router-dom';
import './index.css';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box; 
    /* border: 1px solid black !important; */
    margin: 0;
    padding: 0;
    color: #071b14;
  },
  body {
    font-family: 'Noto Sans TC', sans-serif;
  }
`;

const App: React.FC = () => {
  return (
    <>
      <GlobalStyle />
      <Outlet />
    </>
  );
};

export default App;
