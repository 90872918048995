import React from 'react';
import styled from 'styled-components';

const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const ErrorCode = styled.h1`
  font-size: 4rem;
  margin: 0;
`;

const ErrorMessage = styled.p`
  font-size: 1.5rem;
`;

const NotFound: React.FC = () => {
  return (
    <NotFoundWrapper>
      <ErrorCode>404</ErrorCode>
      <ErrorMessage>Page Not Found</ErrorMessage>
    </NotFoundWrapper>
  );
};

export default NotFound;
